import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Alert, Button, Form, Input, message,
} from 'antd';
import {
  ArrowLeftOutlined,
  LockOutlined,
  MailOutlined,
  MobileOutlined,
  UserOutlined,
} from '@ant-design/icons';
import ReactInputMask from 'react-input-mask';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import SMSCode from './sms-code/sms-code';
import { FormField, ModalWrapper } from '../../shared';

import { sendCode, signup } from '../../../services/signup';

import { phoneNumberFormatter } from '../../../utils/formatters';

const validStatusCodes = [400, 403];

const SignUp = ({ toggle, visible }) => {
  const [isSendCode, setIsSendCode] = useState(false);
  const [error, setError] = useState('');
  const [isSending, setIsSending] = useState(false);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const initialValues = {
    name: '',
    phone: '',
    password: '',
    confirm_password: '',
    email: '',
    code: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Поле обязательно для заполнения'),
    phone: Yup.string()
      .length(10, 'Введите корректный номер телефона')
      .required('Поле обязательно для заполнения'),
    password: Yup.string()
      .min(
        8,
        'Введённый пароль слишком короткий. Он должен содержать как минимум 8 символов.',
      )
      .matches(/[a-zA-Z]/, 'Введённый пароль состоит только из цифр')
      .required('Поле обязательно для заполнения'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Пароли должны совпадать')
      .required('Поле обязательно для заполнения'),
    email: Yup.string()
      .email('Не является E-mail адресом')
      .required('Поле обязательно для заполнения'),
  });

  const submitSendCode = async (values, helpers) => {
    const grToken = await executeRecaptcha('send_code');

    return dispatch(
      sendCode({
        phone: values.phone,
        grToken,
      }),
    )
      .then(() => {
        if (!isSendCode) {
          setIsSendCode(true);
        }
        setIsSending(false);
        message.success('Код подтверждения отправлен');
        return Promise.resolve();
      })
      .catch((e) => {
        if (e.response.data) {
          helpers.setErrors(e.response.data);
        }
        setIsSending(false);
        if (
          validStatusCodes.includes(e.response.status)
          && e.response.data.detail
        ) {
          message.error(e.response.data.detail);
        } else {
          message.error('Не удалось отправить код');
        }
        return Promise.reject(e);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, helpers) => {
      setIsSending(true);

      // Если ранее смс код не отправлялся
      if (!isSendCode) {
        submitSendCode(values, helpers).catch(() => {});
        // Если код смс был отправлен
      } else {
        dispatch(signup(values))
          .then(() => {
            setIsSending(false);
          })
          .catch((e) => {
            setIsSending(false);

            if (e.response.data) {
              formik.setErrors(e.response.data);
            }

            setError('Произошла ошибка');
          });
      }
    },
  });

  useEffect(() => {
    if (isSendCode) {
      setIsSendCode(!isSendCode);
    }
  }, [formik.values.phone]);

  useEffect(() => {
    formik.setFieldValue('code', '');
  }, [isSendCode]);

  const handleCancel = () => {
    formik.resetForm();
    form.resetFields();
    toggle();
  };

  return (
    <ModalWrapper
      title="Регистрация"
      open={visible}
      onCancel={handleCancel}
      width={450}
      closeIcon={<ArrowLeftOutlined />}
      className="sign-up"
    >
      {(setIsFormFieldsChanged) => (
        <>
          <Form
            form={form}
            layout="vertical"
            onFinish={formik.handleSubmit}
            onFieldsChange={() => setIsFormFieldsChanged(true)}
          >
            <FormField
              formik={formik}
              label="Имя"
              name="name"
              placeholder="Введите имя"
              prefix={<UserOutlined />}
            />

            <FormField
              formik={formik}
              label="Номер телефона"
              name="phone"
              placeholder="Введите номер телефона"
              prefix={<MobileOutlined />}
              component={ReactInputMask}
              mask="+7\-(999)-999-99-99"
              maskChar="X"
              onChange={(evt) => formik.setFieldValue(
                'phone',
                phoneNumberFormatter(evt.target.value),
              )}
            >
              {(inputProps) => <Input {...inputProps} />}
            </FormField>

            <FormField
              formik={formik}
              label="Пароль"
              name="password"
              placeholder="Введите пароль"
              component={Input.Password}
              prefix={<LockOutlined />}
            />

            {/* Password */}
            <FormField
              formik={formik}
              label="Повторите пароль"
              name="confirm_password"
              placeholder="Повторите пароль"
              component={Input.Password}
              prefix={<LockOutlined />}
            />

            <FormField
              formik={formik}
              label="Адрес электронной почты"
              name="email"
              placeholder="Введите адрес e-mail"
              prefix={<MailOutlined />}
            />

            {isSendCode && (
              <SMSCode formik={formik} sendCode={submitSendCode} />
            )}

            <Form.Item>
              <Button
                // disabled={!formik.isValid || isSending}
                disabled={isSending}
                type="primary"
                htmlType="submit"
                className="sign-up__btn"
              >
                Зарегистрировать
              </Button>
            </Form.Item>
          </Form>

          {error && (
            <Form.Item>
              <Alert message={error} type="error" />
            </Form.Item>
          )}
        </>
      )}
    </ModalWrapper>
  );
};

SignUp.propTypes = {
  toggle: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default SignUp;
